*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Muli', sans-serif;
}

.aboutHeader{
    /* position: absolute; */
    background-color: #acce77;
    width: 100%;
    height: 20vh;
    background-size: contain;
    /* margin-top: 55px; */
    /* top: 64px; */
    margin-top: 64px;
    text-align: center;
    line-height: 20vh;
    font-size: 30px;
    color: white;
    text-transform: uppercase;
    /* color: black; */
}
.aboutHeader h1{
    position: relative;
    top: 0;
    animation-name: example1;
  animation-duration: 1s;
}

.background {
    position: fixed;
    width: 100%;
    /* height: 50vh; */
    /* background: url("../img/bck02.png"); */
    /* background-size: cover; */
    z-index: -100;
}

.background img {
    position: relative;
    left: 40%;
    /* width: 50%; */
}

.main-content p{
    width: 60%;
    margin: 10vh auto;
    font-size: 23px;
    color: rgb(79, 79, 79);
    letter-spacing: 1px;
}

.main-content img {
    display: block;
    margin: 10vh auto;
    width: 35vw;
    height: 35vw;
    /* height: 400px; */
    border-radius: 30px;
    box-shadow: 10px 10px 1px 1px #acce77;
}

@keyframes example1 {
    from {
        /* font-size: 100px; */
        top: -20px;
        opacity: 0%;
    }
    to {
        /* font-size: 90px; */
        top: 0;
        opacity: 100%;
    }
}


@media only screen and (max-width: 1000px) {

    .background {
        display: none;
    }


    .aboutHeader{
        font-size: 20px;
        margin-top: 70px;
    }

    .aboutHeader h1{
        animation-name: none;
    }

    .main-content p{
        width: 85vw;
        margin: 5vh auto;
        font-size: 18px;
        /* text-align: center; */
    }
    
    .main-content img {
        display: block;
        margin: 6vh auto;
        width: 83vw;
        height: auto;
        /* height: 400px; */
        border-radius: 30px;
        box-shadow: 10px 10px 1px 1px #acce77;
    }

}

